import {LocationModel} from './locationModel.interface';
import {CoachModel} from './coachModel.interface';
import {TagModel} from './tagModel.interface';
import {SubscribersForTraining} from './getTrainingModel.interface';

export interface TrainingListModel extends SubscribersForTraining{
  actual: boolean;
  coachId: number;
  coachName: string;
  deleted: boolean;
  excerpt: string;
  id: number;
  isCoach: boolean;
  isRunner: boolean;
  coach: CoachModel;
  location: LocationModel;
  shortDescription: string;
  nextDate: number;
  nextPlace: string;
  onApprove: boolean;
  past: boolean;
  rating: number;
  repeating: boolean;
  state: TrainingListModel.StateEnum;
  tagList: Array<TagModel>;
  title: string;
  subscribed: boolean;
  onApproveLabel?: boolean;
  waitingListSize?: number;
}

export namespace TrainingListModel {
  export type StateEnum = 'CREATE' | 'NONE' | 'REMOVE';
  export const StateEnum = {
    CREATE: 'CREATE' as StateEnum,
    NONE: 'NONE' as StateEnum,
    REMOVE: 'REMOVE' as StateEnum,
  };
}
