import {AfterViewInit, Directive, OnDestroy, Input} from '@angular/core';
import {NgModel} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[ngModel]input',
})

export class InputSettingsDirective implements AfterViewInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // DECLARATIONS

  @Input('intOnly') private intOnly: boolean = false;

  // INIT DATA

  // CORE

  constructor(
      private ngModel: NgModel,
  ) {

  }

  // HOOKS

  ngAfterViewInit() {

    this.ngModel.valueChanges?.pipe(takeUntil(this.destroyed$))
        .subscribe((val) => {

          if (typeof val === 'string' && val === ' ') {

            this.ngModel.valueAccessor?.writeValue('');

            this.ngModel.viewToModelUpdate('');

            this.ngModel.control.reset();

          } else if (typeof val === 'number' && this.intOnly) {

            if (val.toString().indexOf('.') !== -1) {

              val = parseInt(val.toString().split('.').join(''));

              this.ngModel.valueAccessor?.writeValue(val);

              this.ngModel.viewToModelUpdate(val);
            }

          }

        });

  }

  ngOnDestroy() {

    this.destroyed$.next(true);
    this.destroyed$.complete();

  }

}
