import {ApproveAction} from './approveAction.interface';
import {Lesson} from './lesson.interface';

export interface ApproveLesson {
  approveAction: ApproveAction;
  date: number;
  duration: number;
  id: number;
  lesson: Lesson;
  lessonGroupType: ApproveLesson.LessonGroupTypeEnum;
  place: string;
}

export namespace ApproveLesson {
  export type LessonGroupTypeEnum = 'CURRENT' | 'FUTURE';
  export const LessonGroupTypeEnum = {
    CURRENT: 'CURRENT' as LessonGroupTypeEnum,
    FUTURE: 'FUTURE' as LessonGroupTypeEnum,
  };
}
