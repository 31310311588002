export interface TrainingModelInfScroll {
  pageSize: number;
  filterText?: string;
  offset: number;
  tags?: number[];
}

export namespace TrainingModelInfScroll {
  export type BrowseFilterEnum = 'ACTUAL' | 'DELETED' | 'ON_APPROVAL' | 'PAST';
  export const BrowseFilterEnum = {
    ACTUAL: 'ACTUAL' as BrowseFilterEnum,
    DELETED: 'DELETED' as BrowseFilterEnum,
    ONAPPROVAL: 'ON_APPROVAL' as BrowseFilterEnum,
    PAST: 'PAST' as BrowseFilterEnum,
  };
}
