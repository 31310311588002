import {ApproveLesson} from './approveLesson.interface';
import {ApproveTraining} from './approveTraining.interface';
import {Training} from './training.interface';

export interface ApproveAction {
  approveLessonList: Array<ApproveLesson>;
  approveTraining: ApproveTraining;
  date: number;
  id: number;
  training: Training;
  type: ApproveAction.TypeEnum;
}

export namespace ApproveAction {
  export type TypeEnum = 'CREATE' | 'EDIT' | 'REMOVE' | 'SUBSCRIBE';
  export const TypeEnum = {
    CREATE: 'CREATE' as TypeEnum,
    EDIT: 'EDIT' as TypeEnum,
    REMOVE: 'REMOVE' as TypeEnum,
    SUBSCRIBE: 'SUBSCRIBE' as TypeEnum,
  };
}
