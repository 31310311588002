export interface ApproveActionModel {
  coachId: number;
  coachName: string;
  runnerId: number;
  runnerName: string;
  date: number;
  id: number;
  repeating: boolean;
  tableName: string;
  trainingId: number;
  trainingTitle: string;
  type: ApproveActionModel.TypeEnum;
}

export namespace ApproveActionModel {
  export type TypeEnum = 'CREATE' | 'EDIT' | 'REMOVE' | 'SUBSCRIBE';
  export const TypeEnum = {
    CREATE: 'CREATE' as TypeEnum,
    EDIT: 'EDIT' as TypeEnum,
    REMOVE: 'REMOVE' as TypeEnum,
    SUBSCRIBE: 'SUBSCRIBE' as TypeEnum,
  };
}
