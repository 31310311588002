import {TrainingShortModel} from './trainingShortModel.interface';
import {UserShortInfoModelInterface} from './userShortInfoModel.interface';
import {ApproveActionModel} from './approveActionModel.interface';
import {AssignmentRuleModel} from './assignmentRule.interface';


export interface TestInfoModelInterface {
  author: UserShortInfoModelInterface;
  description?: string;
  id: number;
  mandatory?: boolean;
  name?: string;
  numberOfTasks: number;
  passLimit: number;
  shortDescription?: string;
  status: TestInfoModel.StatusEnum;
  timeToPass: number;
  trainings?: TrainingShortModel[];
  state?: ApproveActionModel.TypeEnum;
  includeAllTasks: boolean;
  modified: Date;
  actualTasksCount?: number;
  assignmentRules: {
    positions: AssignmentRuleModel[];
    projects: AssignmentRuleModel[];
  };
}

export namespace TestInfoModel {
  export type StatusEnum = 'DISABLED' | 'ENABLED' | 'IN_WORK' | 'READY_FOR_APPROVAL';
  export const StatusEnum = {
    DISABLED: 'Disabled' as StatusEnum,
    ENABLED: 'Enabled' as StatusEnum,
    IN_WORK: 'In work' as StatusEnum,
    READY_FOR_APPROVAL: 'Ready for approval' as StatusEnum,
  };
}
