export interface ApproveTrainingModel {
  idTraining: number;
  trainingName: string;
  type: ApproveTrainingModel.TypeEnum;
}

export namespace ApproveTrainingModel {
  export type TypeEnum = 'CREATE' | 'EDIT' | 'REMOVE' | 'SUBSCRIBE';
  export const TypeEnum = {
    CREATE: 'CREATE' as TypeEnum,
    EDIT: 'EDIT' as TypeEnum,
    REMOVE: 'REMOVE' as TypeEnum,
    SUBSCRIBE: 'SUBSCRIBE' as TypeEnum,
  };
}
