import {AssistantInterviewerPairDetailed} from './assistantInterviewerPairDetailed.interface';
import {UserModel} from 'src/app/core/models/userModel.interface';

export interface AssessmentSlotCreate {
  assistantPairId: number;
  startDate: number;
  duration: number;
  place: string;
  location: string;
}

export interface AssessmentSlotFull {
  id: number;
  pairId: number;
  startDate: number;
  duration: number;
  place: string;
  location: string;
  assistantPair: AssistantInterviewerPairDetailed;
  bookedAt: string;
  createdAt: string;
  isBooked: boolean;
  user: UserModel | null;
  englishLevel: string;
  feedbackCount: number;
  isPast?: boolean;
}

export interface CalendarDate {
  id: number;
  bookedCount?: number | null;
  freeCount: number | null;
  totalCount: number | null;
  fetchFrom: number;
  fetchTo: number;
  date: Date;
  isPast: boolean;
  selected?: boolean;
  today?: boolean;
}

export interface SlotsCount {
  bookedCount: number;
  endDate: number;
  freeCount: number;
  startDate: number;
  totalCount: number;
}

export interface AssessmentMappedByDates {
  [key: string]: CalendarDate;
}

export interface IntersectionOfSlots {
  available: boolean,
  slots: AssessmentSlotFull[],
}

export enum EngLvls {
  'A2-' = 'A2-',
  'A2' = 'A2',
  'A2+' = 'A2+',
  'B1-' = 'B1-',
  'B1' = 'B1',
  'B1+' = 'B1+',
  'B2-' = 'B2-',
  'B2' = 'B2',
  'B2+' = 'B2+',
  'C1-' = 'C1-',
  'C1' = 'C1',
  'C1+' = 'C1+',
  'C2-' = 'C2-',
  'C2' = 'C2',
  'C2+' = 'C2+',
  'Native' = 'Native',
}

export interface AssessmentFeedback {
  createdAt: string;
  feedback: string;
  englishLevel: string;
  id: number;
  isUserInterviewer: boolean;
  slot: AssessmentSlotFull;
  user: UserModel;
}
