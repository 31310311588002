export interface UserModel {
  email: string;
  id: number;
  imageUrl: string;
  isChooseUserAdmin: boolean;
  isCoach: boolean;
  isRunner: boolean;
  isCurrentUserAdmin: boolean;
  isGrantAdminRights: boolean;
  isHideEnglish: boolean;
  isMe: boolean;
  isSubscribe: boolean;
  personalHR: string;
  personalHRId: number;
  office: string;
  location: string;
  login: string;
  name: string;
  phone: string;
  department: string;
  position: string;
  role: UserModel.RoleEnum;
  statisticsUsername: string;
  surname: string;
  fullName: string;
  username: string;
}

export namespace UserModel {
  export type RoleEnum = 'ANONYMOUS_USER' | 'TMS_ADMIN' | 'TMS_COACH' | 'TMS_USER';
  export const RoleEnum = {
    ANONYMOUSUSER: 'ANONYMOUS_USER' as RoleEnum,
    TMSADMIN: 'TMS_ADMIN' as RoleEnum,
    TMSCOACH: 'TMS_COACH' as RoleEnum,
    TMSUSER: 'TMS_USER' as RoleEnum,
    TMSHR: 'TMS_HR' as RoleEnum,
  };
}
