import {RepeatingEntity} from './repeatingEntity.interface';

export interface DayOfWeek {
  date: number;
  day: DayOfWeek.DayEnum;
  duration: number;
  id: number;
  place: string;
  repeatingEntity: RepeatingEntity;
}

export namespace DayOfWeek {
  export type DayEnum = 'FRIDAY' | 'MONDAY' | 'SATURDAY' | 'SUNDAY' | 'THURSDAY' | 'TUESDAY' | 'WEDNESDAY';
  export const DayEnum = {
    FRIDAY: 'FRIDAY' as DayEnum,
    MONDAY: 'MONDAY' as DayEnum,
    SATURDAY: 'SATURDAY' as DayEnum,
    SUNDAY: 'SUNDAY' as DayEnum,
    THURSDAY: 'THURSDAY' as DayEnum,
    TUESDAY: 'TUESDAY' as DayEnum,
    WEDNESDAY: 'WEDNESDAY' as DayEnum,
  };
}
