import {QuestionAnswerModelInterface} from './questionAnswerModel.interface';


export interface QuestionModelInterface {
  id?: number;
  question?: string;
  questionAnswerModelList: Array<QuestionAnswerModelInterface>;
  questionFile: string | null;
  testId: number;
  weight: number;
  status?: QuestionModel.StatusEnum
}

export namespace QuestionModel {
  export type StatusEnum = 'ACTUAL' | 'OUTDATED';
  export const StatusEnum = {
    ACTUAL: 'ACTUAL' as StatusEnum,
    OUTDATED: 'OUTDATED' as StatusEnum,
  };
}
