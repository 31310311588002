import {Directive} from '@angular/core';

@Directive({
  selector: '[mouseEnterLeaveAction]',
  host: {
    '(mouseenter)': 'onMouseEnter()',
    '(mouseleave)': 'onMouseLeave()',
  },
  exportAs: 'mouseEnterLeaveDirective',
})
export class MouseEnterLeaveDirective {
    public hoverAct = false;

    constructor() {}

    onMouseEnter() {
        this.hoverAct = true;
    }

    onMouseLeave() {
        this.hoverAct = false;
    }
}
