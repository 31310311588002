// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `icon-snack-bar .snackbar-wrapper {
  color: #fff;
  display: flex;
  justify-content: space-between;
}
icon-snack-bar .snackbar-wrapper .snackbar-info {
  display: flex;
}
icon-snack-bar .snackbar-wrapper .snackbar-info span {
  margin: auto 8px;
}
icon-snack-bar .snackbar-wrapper .snackbar-dismiss {
  display: flex;
  padding-left: 8px;
  border-left: 2px solid #fff;
  cursor: pointer;
}
icon-snack-bar .snackbar-wrapper mat-icon {
  margin: auto 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
