import {TestAttempt} from './testAttempt.model';

export interface TestTakerModelInterface {
  id: number;
  bestResult?: number;
  status?: TestTakerModel.StatusEnum;
  userName: string;
  attempts: TestAttempt[];
}

export namespace TestTakerModel {
  export type StatusEnum = 'CANCELED' | 'FINISHED' | 'IN_PROGRESS' | 'PENDING';
  export const StatusEnum = {
    CANCELED: 'CANCELED' as StatusEnum,
    FINISHED: 'FINISHED' as StatusEnum,
    INPROGRESS: 'IN_PROGRESS' as StatusEnum,
    PENDING: 'PENDING' as StatusEnum,
  };
}
