export interface Lesson {
  date: number;
  duration: number;
  id: number;
  lessonGroup: string;
  place: string;
  setAttendance: boolean;
  state: Lesson.StateEnum;
}

export namespace Lesson {
  export type StateEnum = 'NONE' | 'REMOVAL';
  export const StateEnum = {
    NONE: 'NONE' as StateEnum,
    REMOVAL: 'REMOVAL' as StateEnum,
  };
}
