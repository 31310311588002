import {LocationModel} from './locationModel.interface';
import {RepeatModel} from './repeatModel.interface';
import {TagModel} from './tagModel.interface';
import {PartialTestModel} from './testModel.interface';

export interface SubscribersForTraining {
  subscribersCurSize?: number,
  subscribersMaxSize?: number,
}

export interface GetTrainingModel extends SubscribersForTraining{
  actionId: number;
  canComment: boolean;
  canRate: boolean;
  coachId: number;
  runnerId: number;
  coachName: string;
  runnerName: string;
  cost: number;
  currentGroupLessonsEndDate: number;
  deleted: boolean;
  description: string;
  duration: number;
  endDate: number;
  fixedMembers: boolean;
  groupLessons: boolean;
  hasLeftComment: boolean;
  isApproveSubscribe: boolean;
  isAttendedTraining: boolean;
  mandatoryAssigned: boolean;
  isNotificationSend: boolean;
  isCoach: boolean;
  isRunner: boolean;
  isInner: boolean;
  isRepeating: boolean;
  language: number;
  meetLink: string;
  locationModel: LocationModel;
  maxSize: number;
  onApprove: boolean;
  rating: number;
  repeatModel: RepeatModel;
  shortInfo: string;
  startDate: number;
  subscribeState: GetTrainingModel.SubscribeStateEnum;
  tagList: Array<TagModel>;
  title: string;
  trainingId: number;
  currentSessionCanBeFinished: boolean | null;
  testList: PartialTestModel[];
}

export namespace GetTrainingModel {
  export type SubscribeStateEnum = 'APPROVE' | 'CAN_SUBSCRIBE' | 'NONE';
  export const SubscribeStateEnum = {
    APPROVE: 'APPROVE' as SubscribeStateEnum,
    CANSUBSCRIBE: 'CAN_SUBSCRIBE' as SubscribeStateEnum,
    NONE: 'NONE' as SubscribeStateEnum,
  };
}
