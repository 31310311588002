// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .mat-progress-bar {
  position: fixed;
  top: 0;
  z-index: 99999;
  height: 2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
