import {Directive, ElementRef, forwardRef, HostListener, Input, Renderer2, StaticProvider} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: StaticProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DigitsOnlyDirective),
  multi: true,
};

@Directive({
  selector: 'input[digitsOnly]',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})

export class DigitsOnlyDirective implements ControlValueAccessor {

  @Input('allowDecimal') allowDecimal: boolean = false;

  public onChange: (value: string) => void = () => {};

  public onTouched: () => void = () => {};

  public value: string = '';

  constructor(
      private elementRef: ElementRef,
      private renderer: Renderer2,
  ) {

  }

  @HostListener('input', ['$event']) onInputChange(event: any): void {

    const initialValue = event.target.value;

    if (this.allowDecimal) {

      if (initialValue.match(/^(\d+\.?\d{0,2})?$/)) {

        this.updateTextInput(initialValue, true);

      } else {

        this.updateTextInput(this.value, false);

      }

    } else {

      if (event.data === '.') {

        this.updateTextInput('', true);

      }

      const filteredValue: string = (event.data === 'e') ? this.value : initialValue.replace(/[^0-9]*/g, '');

      this.updateTextInput(filteredValue, this.value !== filteredValue);

    }

  }

  @HostListener('blur') onBlur() {
    this.onTouched();
  }

  private updateTextInput(value: string, propagateChange: boolean): void {

    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);

    if (propagateChange) {

      this.onChange(value);

    }

    this.value = value;
  }

  registerOnChange(fn: any): void {

    this.onChange = fn;

  }

  registerOnTouched(fn: any): void {

    this.onTouched = fn;

  }

  writeValue(value: any): void {

    this.updateTextInput(value ? String(value) : '', false);

  }

  setDisabledState(isDisabled: boolean): void {

    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);

  }

}
