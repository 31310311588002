import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';

import {UIRouterModule} from '@uirouter/angular';
import {appRouting} from './app.routing';
import {routerConfig} from './core/router/router.config';

import {CoreModule} from './core/core.module';
import {ComponentsModule} from './components/components.module';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {IconSnackBarModule} from './components/shared/components/iconSnackBar/iconSnackBar.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import enJson from '../assets/i18n/en.json';

// https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0#gistcomment-2800233
const hashCode = (str: string) => Array.from(str).reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);

export const translateLoader: any = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, '../../assets/i18n/', `.json?cb=${hashCode(JSON.stringify(enJson))}`);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [HttpClient],
      },
    }),
    UIRouterModule.forRoot({
      states: appRouting,
      config: routerConfig,
    }),
    CoreModule,
    ComponentsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatProgressBarModule,
    IconSnackBarModule,
  ],
  providers: [],
  bootstrap: [
    AppComponent,
  ],
  exports: [],
})

export class AppModule {

}
