import {Directive, Input} from '@angular/core';

@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src',
  },
})

export class ImagePreloadDirective {

  // DATA

  @Input() public src!: string;

  @Input() public default!: string;

  // DEFAULT

  public updateUrl() {

    this.src = this.default;

  }

}
