export interface NewsModel {
  actionType: NewsModel.ActionTypeEnum;
  date: number;
  idItem: number;
  tableName: NewsModel.TableNameEnum;
  title: string;
  trainingId?: number;
  testId?: number;
  userId: number;
  userName: string;
  newsId: number;
  viewed: boolean;
  // auxiliary field for news reading
  checked?: boolean;
  read?: boolean;
}

export namespace NewsModel {
  export type ActionTypeEnum =
      'ADD_FILE'
      | 'AUTO_JOIN'
      | 'CREATE'
      | 'EDIT'
      | 'EMAIL'
      | 'JOIN'
      | 'JOIN_M'
      | 'JOIN_WAIT'
      | 'LEAVE'
      | 'LEAVE_ON_DISMISSAL'
      | 'LEAVE_WAIT'
      | 'RATE'
      | 'REJECT'
      | 'REMOVE'
      | 'REMOVE_FILE'
      | 'SET_ATTENDANCE';
  export const ActionTypeEnum = {
    ADDFILE: 'ADD_FILE' as ActionTypeEnum,
    AUTOJOIN: 'AUTO_JOIN' as ActionTypeEnum,
    CREATE: 'CREATE' as ActionTypeEnum,
    EDIT: 'EDIT' as ActionTypeEnum,
    EMAIL: 'EMAIL' as ActionTypeEnum,
    JOIN: 'JOIN' as ActionTypeEnum,
    JOINM: 'JOIN_M' as ActionTypeEnum,
    JOINWAIT: 'JOIN_WAIT' as ActionTypeEnum,
    LEAVE: 'LEAVE' as ActionTypeEnum,
    LEAVEONDISMISSAL: 'LEAVE_ON_DISMISSAL' as ActionTypeEnum,
    LEAVEWAIT: 'LEAVE_WAIT' as ActionTypeEnum,
    RATE: 'RATE' as ActionTypeEnum,
    REJECT: 'REJECT' as ActionTypeEnum,
    REMOVE: 'REMOVE' as ActionTypeEnum,
    REMOVEFILE: 'REMOVE_FILE' as ActionTypeEnum,
    SETATTENDANCE: 'SET_ATTENDANCE' as ActionTypeEnum,
  };
  export type TableNameEnum = 'COMMENT' | 'FEEDBACK' | 'TRAINING' | 'USER';
  export const TableNameEnum = {
    COMMENT: 'COMMENT' as TableNameEnum,
    FEEDBACK: 'FEEDBACK' as TableNameEnum,
    TRAINING: 'TRAINING' as TableNameEnum,
    USER: 'USER' as TableNameEnum,
  };
}
