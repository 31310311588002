
export interface TestModelInfScroll {
  pageSize: number;
  filterText?: string;
  offset: number;
  tags?: number[];
}

export namespace TestModelInfScroll {
  export type TestFilterEnum = 'IN_WORK' | 'READY_FOR_APPROVAL' | 'DISABLED' | 'ENABLED' | 'ALL' | 'STUDENT' | 'AUTHOR';
  export const TestFilterEnum = {
    IN_WORK: 'IN_WORK' as TestFilterEnum,
    READY_FOR_APPROVAL: 'READY_FOR_APPROVAL' as TestFilterEnum,
    DISABLED: 'DISABLED' as TestFilterEnum,
    ENABLED: 'ENABLED' as TestFilterEnum,
    ALL: 'ALL' as TestFilterEnum,
    STUDENT: 'STUDENT' as TestFilterEnum,
    AUTHOR: 'AUTHOR' as TestFilterEnum,
  };
}
