import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'checkFutureDate',
  pure: true,
})

export class CheckFutureDatePipe implements PipeTransform {

  constructor() {

  }

  transform(date: number) {

    const currentDate = Number(new Date());

    return currentDate - date > 0;

  }

}
