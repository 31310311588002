import {AfterViewInit, ChangeDetectorRef, Directive, HostListener, OnInit} from '@angular/core';

@Directive({
    selector: '[appWindowResize]',
    exportAs: 'windowResizeDirective',
})

export class ListenWindowResizeDirective implements OnInit, AfterViewInit {
    private innerWidth!: number;
    public mobile: boolean = false;

    constructor(public cdRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.checkMDScreen();
    }

    ngAfterViewInit() {
        this.checkMDScreen();
    }

    @HostListener('window:resize', ['$event']) onResize() {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth < 1024) {
          this.mobile = true;
        } else {
            this.mobile = false;
        }
        this.cdRef.detectChanges();
    }

    checkMDScreen(): void {
        if (this.innerWidth < 1024) {
            this.mobile = true;
        }
        this.cdRef.detectChanges();
    }
}
