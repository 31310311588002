import {Directive, Input} from '@angular/core';
import {NgScrollbar} from 'ngx-scrollbar';
import {TrainingParticipantStatus} from '../../trainings/info/tabs/participants/participants.component';
import {ReplaySubject} from 'rxjs';
import {RouterService} from '../../../core/router/router.service';
import {takeUntil} from 'rxjs/operators';
import {PaginationDataSource} from '../../../core/services/other/paginatedDataSource';
import {ListenerModel} from '../../../core/models';

@Directive({selector: '[participantsScrollbar]'})
export class ParticipantsScrollbar {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input('participantsScrollbar') scrollbar!: NgScrollbar;

  @Input() participantsStatus!: TrainingParticipantStatus;

  @Input() participantsData!: {[key: string]: PaginationDataSource<ListenerModel>}

  constructor(public routerService: RouterService) {}

  ngOnInit() {

    this.scrollbar.verticalScrolled
        .pipe(takeUntil(this.destroyed$ && this.routerService.routeDestroyed$))
        .subscribe((event: Event) => this.participantsData[this.participantsStatus].onScroll(event));

  }

}
