import {ApproveSubscribe} from './approveSubscribe.interface';
import {Attendance} from './attendance.interface';
import {Comment} from './comment.interface';
import {Feedback} from './feedback.interface';
import {Listener} from './listener.interface';
import {Training} from './training.interface';

export interface User {
  approveSubscribeList: Array<ApproveSubscribe>;
  attendances: Array<Attendance>;
  comments: Array<Comment>;
  dismissed: boolean;
  email: string;
  feedbackList: Array<Feedback>;
  firstName: string;
  hideEnglish: boolean;
  id: number;
  lastName: string;
  login: string;
  phone: string;
  role: User.RoleEnum;
  rvisionUserId: number;
  subScribe: boolean;
  trainingListCoach: Array<Training>;
  trainingListListener: Array<Listener>;
}

export namespace User {
  export type RoleEnum = 'ANONYMOUS_USER' | 'TMS_ADMIN' | 'TMS_COACH' | 'TMS_USER';
  export const RoleEnum = {
    ANONYMOUSUSER: 'ANONYMOUS_USER' as RoleEnum,
    TMSADMIN: 'TMS_ADMIN' as RoleEnum,
    TMSCOACH: 'TMS_COACH' as RoleEnum,
    TMSUSER: 'TMS_USER' as RoleEnum,
  };
}
