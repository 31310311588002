import {AddingTrainingModel} from './addingTrainingModel.interface';
import {ApproveAction} from './approveAction.interface';
import {ApproveSubscribe} from './approveSubscribe.interface';
import {Comment} from './comment.interface';
import {Feedback} from './feedback.interface';
import {FileStorage} from './fileStorage.interface';
import {Lesson} from './lesson.interface';
import {Listener} from './listener.interface';
import {Location} from './location.interface';
import {RepeatingEntity} from './repeatingEntity.interface';
import {Tag} from './tag.interface';
import {User} from './user.interface';

export interface Training {
  addingTrainingModel: AddingTrainingModel;
  approveAction: ApproveAction;
  approveSubscribe: boolean;
  approveSubscribeList: Array<ApproveSubscribe>;
  coach: User;
  commentList: Array<Comment>;
  cost: number;
  countListenerRating: number;
  description: string;
  excerpt: string;
  feedbackList: Array<Feedback>;
  fileStorageList: Array<FileStorage>;
  fixedMembers: boolean;
  groupLessons: boolean;
  id: number;
  inner: boolean;
  language: number;
  lessonList: Array<Lesson>;
  listenerList: Array<Listener>;
  location: Location;
  maxSize: number;
  repeat: boolean;
  repeatingEntity: RepeatingEntity;
  state: Training.StateEnum;
  sumRating: number;
  tagList: Array<Tag>;
  title: string;
}

export namespace Training {
  export type StateEnum = 'CREATE' | 'NONE' | 'REMOVE';
  export const StateEnum = {
    CREATE: 'CREATE' as StateEnum,
    NONE: 'NONE' as StateEnum,
    REMOVE: 'REMOVE' as StateEnum,
  };
}
