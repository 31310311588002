import {Injectable} from '@angular/core';
import {UrlService} from '@uirouter/core';
import {KeycloakService} from 'keycloak-angular';
import {UserService} from '../api/user.service';
import {UserModelSecurity} from '../../models';
import {UIRouter} from '@uirouter/angular';
import {environmentExadel} from '../../../../environments/environment.exadel';

@Injectable({
  providedIn: 'root',
})

export class OauthService {

  // INIT DATA

  public token: string = '';

  public isLoggedIn: boolean = false;

  public redirectUri: string = window.location.href.split(/[?#]/)[0];

  // DATA

  public userCredsData = null as unknown as UserModelSecurity;

  public userRoles = {
    isRunner: false,
    isInterviewer: false,
    isAssistant: false,
    isAdmin: false,
    isCoach: false,
    isUser: false,
    isAnonymous: false,
    isHR: false,
    isTestsAuthor: false,
  };

  // CORE

  constructor(
      protected urlService: UrlService,
      protected keycloak: KeycloakService,
      protected userService: UserService,
      protected router: UIRouter,
  ) {

  }

  // INIT

  public async init() {
    this.urlService.deferIntercept();

    await this.initKeycloak().then(async () => {
      if (this.isLoggedIn) {
        this.redirectUri = '';
        await this.getSetToken();
        await this.getCurrentUserData();
        this.setUserRole();
      }
    });
    return this.activateUrlRouter();
  }

  public async initKeycloak() {

    return this.keycloak.init({
      config: environmentExadel.keyCloack.config,
      initOptions: {
        flow: 'standard',
        onLoad: 'check-sso',
        pkceMethod: 'S256',
      },
    }).then((res) => {
      this.isLoggedIn = res;
      return res;
    });
  }

  // AUTH FLOW

  public login() {
    if (this.redirectUri.includes('login')) {
      this.redirectUri = this.redirectUri.replace('login', 'my-trainings');
    }
    return this.keycloak.login({redirectUri: this.redirectUri});
  }

  public async getSetToken() {
    this.token = await this.keycloak.getToken();
    return this.token;
  }

  public setUserRole() {
    this.userRoles.isRunner = !!this.userCredsData.runner;
    this.userRoles.isInterviewer = !!this.userCredsData.interviewer;
    this.userRoles.isAssistant = !!this.userCredsData.assistant;
    this.userRoles.isTestsAuthor = !!this.userCredsData.userAuthorOfTests;

    if (this.userCredsData.accessRights === 'ROLE_TMS_ADMIN') {
      this.userRoles.isAdmin = true;
      return this.userRoles.isAdmin;
    }

    if (this.userCredsData.accessRights === 'ROLE_TMS_COACH') {
      this.userRoles.isCoach = true;
      return this.userRoles.isCoach;
    }

    if (this.userCredsData.accessRights === 'ROLE_TMS_USER') {
      this.userRoles.isUser = true;
      return this.userRoles.isUser;
    }

    if (this.userCredsData.accessRights === 'ROLE_TMS_HR') {
      this.userRoles.isHR = true;
      return this.userRoles.isHR;
    }

    this.userRoles.isAnonymous = true;
  }

  public async logOut() {
    window.localStorage.clear();
    this.isLoggedIn = false;
    this.userCredsData = null as unknown as UserModelSecurity;
    return this.keycloak.logout();
  }

  // END AUTH FLOW

  public activateUrlRouter() {
    this.urlService.listen();
    this.urlService.sync();
  }

  public get userId(): number | null {
    if (this.userCredsData?.userId) {
      return this.userCredsData.userId;
    }
    return null;
  }
  // DEFAULT

  public async getCurrentUserData() {
     // @ts-ignore
    return this.userService.getCreds().toPromise().then((res) => {
      // @ts-ignore
      this.userCredsData = res;
      return res;
    });
  }
}
