import {Directive} from '@angular/core';
import {Validator, NG_VALIDATORS, AbstractControl} from '@angular/forms';

@Directive({
  selector: '[noWhitespace][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: NoWhitespaceValidator,
      multi: true,
    }],
})
export class NoWhitespaceValidator implements Validator {

  public validate(control: AbstractControl) : {[key: string]: boolean} | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return !isWhitespace ? null : {whitespace: true};
  }

}
