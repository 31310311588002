import {AfterViewChecked, Directive, Input} from '@angular/core';
import {NgForm} from '@angular/forms';

@Directive({
  selector: '[setErrorForPastLesson]',
})
export class SetErrorForPastLessonDirective implements AfterViewChecked {

  @Input('setErrorForPastLesson') lessonDate!: number;

  @Input('setErrorForPastLessonMinDate') minDate!: number;

  @Input() form!: NgForm;

  @Input() formControlNumber!: number;

  public ngAfterViewChecked() {

    if (this.form.form.get(`lessonDate${this.formControlNumber}`) && this.lessonDate < this.minDate) {
      this.form.form.get(`lessonDate${this.formControlNumber}`)?.setErrors({'minDate': true});
      this.form.form.get(`lessonDate${this.formControlNumber}`)?.markAsTouched();
    }
  }
}
