import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[observeVisibility]',
})

export class ObserveVisibilityDirective implements OnDestroy, OnInit, AfterViewInit {

  // DECLARATIONS

  @Input() debounceTime = 0;
  @Input() threshold = 1;

  @Output() visible = new EventEmitter<HTMLElement>();

  private observer: IntersectionObserver | undefined;

  // INIT DATA

  private entryPushed: boolean = false;

  // CORE

  constructor(
      private element: ElementRef,
  ) {
  }

  // HOOKS

  ngOnInit(): void {

    this.createObserver();

  }

  ngAfterViewInit(): void {

    this.startObservingElements();

  }

  ngOnDestroy(): void {

    if (this.observer) {

      this.observer.disconnect();
      this.observer = undefined;

    }

  }

  // DEFAULT

  private createObserver(): void {

    const options = {
      rootMargin: '0px',
      threshold: this.threshold,
    };

    const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0;

    this.observer = new IntersectionObserver((entries) => {

      entries.forEach((entry) => {

        if (this.entryPushed || entry.target.classList.contains('viewed')) {

          this.ngOnDestroy();

          return;

        }

        if (isIntersecting(entry)) {

          this.visible.emit();

          this.entryPushed = true;

        }

      });

    }, options);

  }

  private startObservingElements(): void {

    if (!this.observer) {

      return;

    }

    this.observer.observe(this.element.nativeElement);

  }

}
