export enum FilterRuleType {
  position = 'POSITION',
  project = 'PROJECT',
  department = 'DEPARTMENT',
  office = 'OFFICE',
}

export interface FilterRule {
  ruleId: number;
  type: FilterRuleType;
  value: string;
}
