import {EditTrainingModelInterface} from './editTrainingModel.interface';
import {Training} from './training.interface';


export interface TrainingApprovalInterface {
  approveType?: TrainingApproval.ApproveTypeEnum;
  date?: Date;
  editTrainingModel?: EditTrainingModelInterface;
  id?: number;
  training?: Training;
}

export namespace TrainingApproval {
  export type ApproveTypeEnum = 'CREATE' | 'EDIT' | 'REMOVE';
  export const ApproveTypeEnum = {
    CREATE: 'CREATE' as ApproveTypeEnum,
    EDIT: 'EDIT' as ApproveTypeEnum,
    REMOVE: 'REMOVE' as ApproveTypeEnum,
  };
}
