import {Directive, Input} from '@angular/core';
import {ControlContainer, NgForm, NgModel} from '@angular/forms';

@Directive({
  selector: '[ngModel][durationValidity]',
  providers: [
    NgModel,
    {
      provide: ControlContainer,
      useFactory: function(form: NgForm) {
        return form;
      },
      deps: [NgForm],
    },
  ],
  host: {
    '(ngModelChange)': 'onInputChange($event)',
  },
})

export class DurationValidityDirective {

  // DECLARATIONS

  @Input() private name!: string;

  // INIT DATA

  public $event!: Date;

  // CORE

  constructor(
      private ngModel: NgModel,
      private form: NgForm,
  ) {

  }

  // HOST

  public onInputChange($event: Date) {

    if (this.name) {

      if ($event.getHours() === 0 && $event.getMinutes() === 0 && this.ngModel.control.touched) {

        this.form.controls[this.name].setErrors({minDuration: true});

        this.ngModel.control.setErrors({minDuration: true});

      } else {

        this.form.controls[this.name].markAsTouched();

        this.form.controls[this.name].setErrors(null);

        this.ngModel.control.markAsTouched();

        this.ngModel.control.setErrors(null);

      }

    }

  }

}
