export interface ApproveLessonModel {
  lessonId?: number;
  newDate: number;
  newDuration: number;
  newLessonGroupType: ApproveLessonModel.NewLessonGroupTypeEnum;
  newPlace: string;
  oldDate: number;
  oldDuration: number;
  oldLessonGroupType: ApproveLessonModel.OldLessonGroupTypeEnum;
  oldPlace: string;
  comment?: string;
  rejectComment?: string;
  groupLessons: boolean;
}

export namespace ApproveLessonModel {
  export type NewLessonGroupTypeEnum = 'CURRENT' | 'FUTURE';
  export const NewLessonGroupTypeEnum = {
    CURRENT: 'CURRENT' as NewLessonGroupTypeEnum,
    FUTURE: 'FUTURE' as NewLessonGroupTypeEnum,
  };
  export type OldLessonGroupTypeEnum = 'CURRENT' | 'FUTURE';
  export const OldLessonGroupTypeEnum = {
    CURRENT: 'CURRENT' as OldLessonGroupTypeEnum,
    FUTURE: 'FUTURE' as OldLessonGroupTypeEnum,
  };
}
