import {Injectable} from '@angular/core';
import {HttpClient, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UIRouter} from '@uirouter/core';
import {OauthService} from '../services/other/oauth.service';
import {EventsService} from '../services/other/events.service';
import {SnackBarMessagesService} from '../services/other/snackBarMessages.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ERROR_MESSAGES} from 'src/app/components/shared/constants/snackbar-messages';

@Injectable()

export class ErrorsInterceptor implements HttpInterceptor {

  constructor(
      protected http: HttpClient,
      protected router: UIRouter,
      protected oauthService: OauthService,
      protected eventsService: EventsService,
      protected snackBarMessagesService: SnackBarMessagesService,
      protected dialogRef: MatDialog,
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    const regexp500 = RegExp('\s*5\\d{2}\s');

    return next.handle(req).pipe(
        // retry(1) - bug when session shutdown,
        catchError((err: any) => {

          this.eventsService.setProgressState(false);

          if (err.status === 401) {

            this.dialogRef.closeAll();

            return this.oauthService.logOut();

          }

          if ((err.status === 403 || err.status === 409) && err.error) {

            if (this.router.globals.current.url === '/tasks' && err.status === 409) {

              return throwError(err);

            }

            this.snackBarMessagesService.showErrorMessage(err.error);

            return throwError(err);

          }

          if (err.status === 404) {

            this.dialogRef.closeAll();

            this.router.stateService.go('app-notFound');

            return throwError(err);

          }

          if (err.status === 413) {

            this.snackBarMessagesService.showErrorMessage('Upload limit of 15 MB is reached.');

            return throwError(err);

          }

          if (regexp500.test(err.status)) {

            this.dialogRef.closeAll();

            this.router.stateService.go('app-errorPage');

            return throwError(err);

          }

          if (err.error && err.error.message) {

            this.snackBarMessagesService.showErrorMessage(err.error.message);

          } else {

            this.snackBarMessagesService.showErrorMessage(ERROR_MESSAGES.SOMETHING_WRONG);

          }

          return throwError(err);

        }),
    );

  }

}
