import {Training} from './training.interface';
import {User} from './user.interface';

export interface Listener {
  canRate: boolean;
  createDate: number;
  deleteFlag: boolean;
  id: number;
  reason: string;
  state: Listener.StateEnum;
  training: Training;
  updateDate: number;
  user: User;
}

export namespace Listener {
  export type StateEnum = 'ACCEPTED' | 'LEAVE' | 'WAITING' | 'WAITING_LEAVE';
  export const StateEnum = {
    ACCEPTED: 'ACCEPTED' as StateEnum,
    LEAVE: 'LEAVE' as StateEnum,
    WAITING: 'WAITING' as StateEnum,
    WAITINGLEAVE: 'WAITING_LEAVE' as StateEnum,
  };
}
