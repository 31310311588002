import {LessonModel} from './lessonModel.interface';
import {TagModel} from './tagModel.interface';

export interface ArchiveTraining {
  coachId: number;
  coachName: string;
  cost: number;
  description: string;
  duration: number;
  id: number;
  isRepeating: boolean;
  language: number;
  lessonModels: Array<LessonModel>;
  maxSize: number;
  shortInfo: string;
  tagList: Array<TagModel>;
  title: string;
  trainingId: number;
  type: ArchiveTraining.TypeEnum;
}

export namespace ArchiveTraining {
  export type TypeEnum = 'CURRENT' | 'FUTURE' | 'NONE' | 'PASSED';
  export const TypeEnum = {
    CURRENT: 'CURRENT' as TypeEnum,
    FUTURE: 'FUTURE' as TypeEnum,
    NONE: 'NONE' as TypeEnum,
    PASSED: 'PASSED' as TypeEnum,
  };
}
