export interface ResponseEntity {
  body: any;
  statusCode: ResponseEntity.StatusCodeEnum;
  statusCodeValue: number;
}

export namespace ResponseEntity {
  // eslint-disable-next-line max-len
  export type StatusCodeEnum =
      'ACCEPTED'
      | 'ALREADY_REPORTED'
      | 'BAD_GATEWAY'
      | 'BAD_REQUEST'
      | 'BANDWIDTH_LIMIT_EXCEEDED'
      | 'CHECKPOINT'
      | 'CONFLICT'
      | 'CONTINUE'
      | 'CREATED'
      | 'DESTINATION_LOCKED'
      | 'EXPECTATION_FAILED'
      | 'FAILED_DEPENDENCY'
      | 'FORBIDDEN'
      | 'FOUND'
      | 'GATEWAY_TIMEOUT'
      | 'GONE'
      | 'HTTP_VERSION_NOT_SUPPORTED'
      | 'IM_USED'
      | 'INSUFFICIENT_SPACE_ON_RESOURCE'
      | 'INSUFFICIENT_STORAGE'
      | 'INTERNAL_SERVER_ERROR'
      | 'I_AM_A_TEAPOT'
      | 'LENGTH_REQUIRED'
      | 'LOCKED'
      | 'LOOP_DETECTED'
      | 'METHOD_FAILURE'
      | 'METHOD_NOT_ALLOWED'
      | 'MOVED_PERMANENTLY'
      | 'MOVED_TEMPORARILY'
      | 'MULTIPLE_CHOICES'
      | 'MULTI_STATUS'
      | 'NETWORK_AUTHENTICATION_REQUIRED'
      | 'NON_AUTHORITATIVE_INFORMATION'
      | 'NOT_ACCEPTABLE'
      | 'NOT_EXTENDED'
      | 'NOT_FOUND'
      | 'NOT_IMPLEMENTED'
      | 'NOT_MODIFIED'
      | 'NO_CONTENT'
      | 'OK'
      | 'PARTIAL_CONTENT'
      | 'PAYLOAD_TOO_LARGE'
      | 'PAYMENT_REQUIRED'
      | 'PERMANENT_REDIRECT'
      | 'PRECONDITION_FAILED'
      | 'PRECONDITION_REQUIRED'
      | 'PROCESSING'
      | 'PROXY_AUTHENTICATION_REQUIRED'
      | 'REQUESTED_RANGE_NOT_SATISFIABLE'
      | 'REQUEST_ENTITY_TOO_LARGE'
      | 'REQUEST_HEADER_FIELDS_TOO_LARGE'
      | 'REQUEST_TIMEOUT'
      | 'REQUEST_URI_TOO_LONG'
      | 'RESET_CONTENT'
      | 'SEE_OTHER'
      | 'SERVICE_UNAVAILABLE'
      | 'SWITCHING_PROTOCOLS'
      | 'TEMPORARY_REDIRECT'
      | 'TOO_EARLY'
      | 'TOO_MANY_REQUESTS'
      | 'UNAUTHORIZED'
      | 'UNAVAILABLE_FOR_LEGAL_REASONS'
      | 'UNPROCESSABLE_ENTITY'
      | 'UNSUPPORTED_MEDIA_TYPE'
      | 'UPGRADE_REQUIRED'
      | 'URI_TOO_LONG'
      | 'USE_PROXY'
      | 'VARIANT_ALSO_NEGOTIATES';
  export const StatusCodeEnum = {
    ACCEPTED: 'ACCEPTED' as StatusCodeEnum,
    ALREADYREPORTED: 'ALREADY_REPORTED' as StatusCodeEnum,
    BADGATEWAY: 'BAD_GATEWAY' as StatusCodeEnum,
    BADREQUEST: 'BAD_REQUEST' as StatusCodeEnum,
    BANDWIDTHLIMITEXCEEDED: 'BANDWIDTH_LIMIT_EXCEEDED' as StatusCodeEnum,
    CHECKPOINT: 'CHECKPOINT' as StatusCodeEnum,
    CONFLICT: 'CONFLICT' as StatusCodeEnum,
    CONTINUE: 'CONTINUE' as StatusCodeEnum,
    CREATED: 'CREATED' as StatusCodeEnum,
    DESTINATIONLOCKED: 'DESTINATION_LOCKED' as StatusCodeEnum,
    EXPECTATIONFAILED: 'EXPECTATION_FAILED' as StatusCodeEnum,
    FAILEDDEPENDENCY: 'FAILED_DEPENDENCY' as StatusCodeEnum,
    FORBIDDEN: 'FORBIDDEN' as StatusCodeEnum,
    FOUND: 'FOUND' as StatusCodeEnum,
    GATEWAYTIMEOUT: 'GATEWAY_TIMEOUT' as StatusCodeEnum,
    GONE: 'GONE' as StatusCodeEnum,
    HTTPVERSIONNOTSUPPORTED: 'HTTP_VERSION_NOT_SUPPORTED' as StatusCodeEnum,
    IMUSED: 'IM_USED' as StatusCodeEnum,
    INSUFFICIENTSPACEONRESOURCE: 'INSUFFICIENT_SPACE_ON_RESOURCE' as StatusCodeEnum,
    INSUFFICIENTSTORAGE: 'INSUFFICIENT_STORAGE' as StatusCodeEnum,
    INTERNALSERVERERROR: 'INTERNAL_SERVER_ERROR' as StatusCodeEnum,
    IAMATEAPOT: 'I_AM_A_TEAPOT' as StatusCodeEnum,
    LENGTHREQUIRED: 'LENGTH_REQUIRED' as StatusCodeEnum,
    LOCKED: 'LOCKED' as StatusCodeEnum,
    LOOPDETECTED: 'LOOP_DETECTED' as StatusCodeEnum,
    METHODFAILURE: 'METHOD_FAILURE' as StatusCodeEnum,
    METHODNOTALLOWED: 'METHOD_NOT_ALLOWED' as StatusCodeEnum,
    MOVEDPERMANENTLY: 'MOVED_PERMANENTLY' as StatusCodeEnum,
    MOVEDTEMPORARILY: 'MOVED_TEMPORARILY' as StatusCodeEnum,
    MULTIPLECHOICES: 'MULTIPLE_CHOICES' as StatusCodeEnum,
    MULTISTATUS: 'MULTI_STATUS' as StatusCodeEnum,
    NETWORKAUTHENTICATIONREQUIRED: 'NETWORK_AUTHENTICATION_REQUIRED' as StatusCodeEnum,
    NONAUTHORITATIVEINFORMATION: 'NON_AUTHORITATIVE_INFORMATION' as StatusCodeEnum,
    NOTACCEPTABLE: 'NOT_ACCEPTABLE' as StatusCodeEnum,
    NOTEXTENDED: 'NOT_EXTENDED' as StatusCodeEnum,
    NOTFOUND: 'NOT_FOUND' as StatusCodeEnum,
    NOTIMPLEMENTED: 'NOT_IMPLEMENTED' as StatusCodeEnum,
    NOTMODIFIED: 'NOT_MODIFIED' as StatusCodeEnum,
    NOCONTENT: 'NO_CONTENT' as StatusCodeEnum,
    OK: 'OK' as StatusCodeEnum,
    PARTIALCONTENT: 'PARTIAL_CONTENT' as StatusCodeEnum,
    PAYLOADTOOLARGE: 'PAYLOAD_TOO_LARGE' as StatusCodeEnum,
    PAYMENTREQUIRED: 'PAYMENT_REQUIRED' as StatusCodeEnum,
    PERMANENTREDIRECT: 'PERMANENT_REDIRECT' as StatusCodeEnum,
    PRECONDITIONFAILED: 'PRECONDITION_FAILED' as StatusCodeEnum,
    PRECONDITIONREQUIRED: 'PRECONDITION_REQUIRED' as StatusCodeEnum,
    PROCESSING: 'PROCESSING' as StatusCodeEnum,
    PROXYAUTHENTICATIONREQUIRED: 'PROXY_AUTHENTICATION_REQUIRED' as StatusCodeEnum,
    REQUESTEDRANGENOTSATISFIABLE: 'REQUESTED_RANGE_NOT_SATISFIABLE' as StatusCodeEnum,
    REQUESTENTITYTOOLARGE: 'REQUEST_ENTITY_TOO_LARGE' as StatusCodeEnum,
    REQUESTHEADERFIELDSTOOLARGE: 'REQUEST_HEADER_FIELDS_TOO_LARGE' as StatusCodeEnum,
    REQUESTTIMEOUT: 'REQUEST_TIMEOUT' as StatusCodeEnum,
    REQUESTURITOOLONG: 'REQUEST_URI_TOO_LONG' as StatusCodeEnum,
    RESETCONTENT: 'RESET_CONTENT' as StatusCodeEnum,
    SEEOTHER: 'SEE_OTHER' as StatusCodeEnum,
    SERVICEUNAVAILABLE: 'SERVICE_UNAVAILABLE' as StatusCodeEnum,
    SWITCHINGPROTOCOLS: 'SWITCHING_PROTOCOLS' as StatusCodeEnum,
    TEMPORARYREDIRECT: 'TEMPORARY_REDIRECT' as StatusCodeEnum,
    TOOEARLY: 'TOO_EARLY' as StatusCodeEnum,
    TOOMANYREQUESTS: 'TOO_MANY_REQUESTS' as StatusCodeEnum,
    UNAUTHORIZED: 'UNAUTHORIZED' as StatusCodeEnum,
    UNAVAILABLEFORLEGALREASONS: 'UNAVAILABLE_FOR_LEGAL_REASONS' as StatusCodeEnum,
    UNPROCESSABLEENTITY: 'UNPROCESSABLE_ENTITY' as StatusCodeEnum,
    UNSUPPORTEDMEDIATYPE: 'UNSUPPORTED_MEDIA_TYPE' as StatusCodeEnum,
    UPGRADEREQUIRED: 'UPGRADE_REQUIRED' as StatusCodeEnum,
    URITOOLONG: 'URI_TOO_LONG' as StatusCodeEnum,
    USEPROXY: 'USE_PROXY' as StatusCodeEnum,
    VARIANTALSONEGOTIATES: 'VARIANT_ALSO_NEGOTIATES' as StatusCodeEnum,
  };
}
