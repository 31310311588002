export interface ListenerModel {
  email: string;
  external: boolean;
  giveFeedback: boolean;
  lastUpdate: number;
  position: string;
  state: ListenerModel.StateEnum;
  userId: number;
  username: string;
  waiting: boolean;
}

export namespace ListenerModel {
  export type StateEnum = 'ACCEPTED' | 'LEAVE' | 'WAITING' | 'WAITING_LEAVE';
  export const StateEnum = {
    ACCEPTED: 'ACCEPTED' as StateEnum,
    LEAVE: 'LEAVE' as StateEnum,
    WAITING: 'WAITING' as StateEnum,
    WAITINGLEAVE: 'WAITING_LEAVE' as StateEnum,
  };
}
